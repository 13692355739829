
import { defineComponent, onMounted, computed, ref } from 'vue'

import Customers from '@/core/services/Callable/Customers';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from '@/router/clean';
import { showHide, customer } from '@/store/stateless';
import { store } from "@/store";

export default defineComponent({
    name: 'customer-list',

    setup() {

        const filter = computed(() => {
            return store.state.customer.filter
        })

        const reset = async() => {
            Customers.setFilter({
                agent: 'all',
                customer_status_id: -1,
                end_date: "",
                index: 1,
                keyword: "",
                lead_status_id: -1,
                per_page: 50,
                page: 1,
                start_date: "",
                task_due_dates: -1,
                hot_enquiry: -1
            })
        }

        onMounted(async () => {
            setCurrentPageBreadcrumbs("Leads & Tasks", []);
            await reset()
            customer.checkedTransfer = [];


            const page = router.currentRoute.value.query ? router.currentRoute.value.query.page : 1;
            if(!store.getters.showByRole([1,9,13,14])) filter.value.agent = store.getters.myId
            else if(store.getters.showByRole([1,14]))  filter.value.agent = 'all'
            else if(store.getters.showByRole([9,13]))  filter.value.agent = -1
            
            console.log('filter',filter.value)
            await getLists(page);
        })

        const getLists = async (e: any) => {
            const page = parseInt(e ? e : 1);
            
            filter.value.page = page;
            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            await Customers.customerLists(filter.value)
        }

        return {
            filter,
            showHide
        }
    },
})
